<template>
  <el-dialog title="补充材料" :visible.sync="visible" custom-class="min-width" :close-on-click-modal="false" draggable>
    <el-form ref="dataFormRef" :model="form" :rules="dataRules" formDialogRef label-width="250px" v-loading="loading">
      <el-row :gutter="24">
        <el-col :span="12" class="mb20" v-if="form.personType!='seller'">
          <el-form-item label="统一社会信用代码" prop="unifiedSocialCreditCode">
            <el-input v-model="form.unifiedSocialCreditCode" placeholder="请输入统一社会信用代码" />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb20" v-if="form.personType!='seller'">
          <el-form-item label="联系人地址" prop="contactAddress">
            <el-input v-model="form.contactAddress" placeholder="请输入联系人地址" />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10" >
          <el-form-item label="土地估价" prop="gujia">
            <el-input v-model="form.gujia"  placeholder="请输入土地估价">
              <template v-slot:append>万元</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24" class="mb20">
          <el-divider>文件上传</el-divider>
        </el-col>
        <el-col :span="12" class="mb20">
          <el-form-item label="国有建设用地使用权出让合同" prop="landOutContract">
            <div style="flex-direction: row; column-gap: 12px">
              <div >
                <upload v-if="visible" @childUpload="parentGetData" @on-preview="previewFile"  name="landOutContract"  />
              </div>
              <ul  class="navWrap_center_ul" style="width: 150px;">
                <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                    v-for="(item, index) in form.landOutContract"
                    :key="index"
                    @click="downloadFile(item.url)"
                >
                  <span > {{ item.name }}</span>
                </li>
              </ul>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="mb20">
          <el-form-item label="转让协议或合同" prop="transferContract">
            <div  >
              <div style="flex-direction: row; column-gap: 12px">
                <upload v-if="visible" @childUpload="parentGetData"  @on-preview="previewFile" name="transferContract" />
              </div>
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(transferContractItem, index) in form.transferContract"
                  :key="index"
                  @click="downloadFile(transferContractItem.url)"
              >
                <span >{{ transferContractItem.name }}</span>
              </li>
            </ul>
          </el-form-item>
        </el-col>



        <el-col :span="12" class="mb20">
          <el-form-item label="评估报告" prop="landEvaluateReport">
            <div style="display: flex; flex-direction: row; column-gap: 12px" >
              <upload   v-if="visible"  @childUpload="parentGetData"  @on-preview="previewFile" name="landEvaluateReport" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li  style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                   v-for="(landEvaluateReportItem, index) in form.landEvaluateReport"
                   :key="index"
                   @click="downloadFile(landEvaluateReportItem.url)" >
                {{ landEvaluateReportItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb20">
          <el-form-item label="资产评审报告" prop="assetsEvaluateReport">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload  v-if="visible"  @childUpload="parentGetData"  @on-preview="previewFile" name="assetsEvaluateReport" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(assetsEvaluateReportItem, index) in form.assetsEvaluateReport"
                  :key="index"
                  @click="downloadFile(assetsEvaluateReportItem.url)" >
                {{ assetsEvaluateReportItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>


        <el-col :span="12" class="mb20">
          <el-form-item label="国有企业主管部门同意转让的批准文件" prop="permitFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="permitFiles" />
            </div>
            <ul class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(permitFilesItem, index) in form.permitFiles"
                  :key="index"
                  @click="downloadFile(permitFilesItem.url)" >
                {{ permitFilesItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb20">
          <el-form-item label="委托文件" prop="proxyFiles">
            <div  >
              <div style="display: flex; flex-direction: row; column-gap: 12px">

                <upload  @childUpload="parentGetData" @on-preview="previewFile"  name="proxyFiles"  />
              </div>
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(itemProxy, index) in form.proxyFiles"
                  :key="index"
                  @click="downloadFile(itemProxy.url)"
              >
                {{ itemProxy.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer >
			<span class="dialog-footer" >
				<el-button @click="visible = false">取消</el-button>
				<el-button type="primary" @click="onSubmit" :disabled="loading">确认</el-button>
			</span>
    </template>

  </el-dialog>
</template>

<script setup  name="demandTradeFilesDialog">
import { useDict } from '@/hooks/dict';
import {Message} from 'element-ui'
import {reactive, ref, defineAsyncComponent, nextTick} from 'vue'
import  CommonService  from '@/service/CommonService';
const { transaction_person_type } = useDict('transaction_person_type');

const Upload = defineAsyncComponent(() => import('@/components/Upload/NewFile.vue'));

const parentGetData = (value,name) => {
  form[name] = value;

};
const previewFile = (file) => {
  window.open(file.url);
};


// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const loading = ref(false);

// 提交表单数据
const form = reactive({
  transactionId: '',
  transactionType: '',
  unifiedSocialCreditCode: '',
  contactAddress:'',
  gujia:'',
  userId: localStorage.getItem('user_id')
});


const validatePrice = async (rule, value, callback) => {
  // console.log(value,typeof value , !isNaN(value));
  let exp = /^[+]?\d*(\.\d*)?(e[+-]?\d+)?$/;
  if(form.transactionWay === "auction"  || form.transactionWay ==="listing") {
    if (!exp.test(value) || value <= 0 ) {
      callback(new Error('请输入价格'));
    }
  }

}


const downloadFile = (file) => {
  window.open(file);
};
// 定义校验规则
const dataRules = ref({
//  landOutContract: [{ required: true, message: '国有建设用地使用权出让合同不能为空', trigger: 'blur' }],
 // transferContract: [{ required: true, message: '转让协议或合同不能为空', trigger: 'blur' }],
 // landEvaluateReport: [{ required: true, message: '评估报告不能为空', trigger: 'blur' }],
 // assetsEvaluateReport: [{ required: true, message: '资产评审报告不能为空', trigger: 'blur' }],
  gujia: [{required: true,validator: validatePrice, trigger: 'change' }],
  unifiedSocialCreditCode: [{ required: true, message: '请输入统一社会信用码', trigger: 'change' }]
});

let param_data= reactive({
  transactionId: '',
  transactionType: '',
  unifiedSocialCreditCode: '',
  contactAddress:'',
  gujia:'',
  userId: localStorage.getItem('user_id'),
  landOutContract: [], //营业执照/机构代码/法人证明/法人身份证(复印件加盖单位印章) List
  transferContract: [], //企业章程文件 List
  landReport: [] , //	股东会决议 List
  assetsEvaluateReport: [] , //经办人委托书及其身份证复印件 List, 代办才有此字段

});
// 打开弹窗
const openDialog = (param , type) => {
  form.personType = '';

  form.fileId = param.transactionId ;
  form.id = param.transactionId ;

  visible.value = true;

  form.transactionType = param.transactionType;
  form.identityFiles = [];
  form.companyFiles = [];
  form.shareholderFiles = [];
  form.proxyFiles = [];


  // 重置表单数据
  nextTick(() => {
    form.fileId > 0 ? '' : form.fileId = null;
    dataFormRef.value?.resetFields();
  });
  if  ( (type === 'listing'  || type === 'auction' )&& param.id > 0) {

    if(type === 'listing'  || type === 'auction'   )
    {

      Object.assign(form, param);

      form.identityFiles = param.identityFiles;
      form.companyFiles = param.companyFiles;
      form.shareholderFiles = param.shareholderFiles;
      form.proxyFiles = param.proxyFiles;

      Object.assign(form, param);
      console.log(param)
      getAgreementTradeFileData(form.fileId);
    }else
    {
      form.fileId = param.id;
      if (form.fileId > 0 ) {
        getAgreementTradeFileData(form.fileId);
      }
    }

  }

};

// 提交
const onSubmit = async () => {
  const valid = await dataFormRef.value.validate().catch(() => {});
  console.log(valid);
  if (!valid) return false;


  try {
    loading.value = true;


    for (let arra_val  in param_data.transferContract)
    {
      let url=param_data.transferContract[arra_val].url;
      let result= form.transferContract.find(obj => obj.url ==url );
      if (!result) {
        form.transferContract.push(param_data.transferContract[arra_val])
      }
    }

    for (let arra_val  in param_data.landOutContract)
    {
      let url=param_data.landOutContract[arra_val].url;
      let result= form.landOutContract.find(obj => obj.url ==url );
      if (!result) {
        form.landOutContract.push(param_data.landOutContract[arra_val])
      }
    }
    if (param_data!=null && param_data.landEvaluateReport!=null  && param_data.landEvaluateReport!=undefined)
    {
      for (let arra_val  in param_data.landEvaluateReport)
      {
        let url=param_data.landEvaluateReport[arra_val].url;
        let result= form.landEvaluateReport.find(obj => obj.url ==url );
        if (!result) {
          form.landEvaluateReport.push(param_data.landEvaluateReport[arra_val])
        }
      }
    }


    if(param_data!=null && param_data.assetsEvaluateReport!=null  && param_data.assetsEvaluateReport!=undefined)
    {
      for (let arra_val  in param_data.assetsEvaluateReport)
      {
        let url=param_data.assetsEvaluateReport[arra_val].url;
        let result= form.assetsEvaluateReport.find(obj => obj.url ==url );
        if (!result) {
          form.assetsEvaluateReport.push(param_data.assetsEvaluateReport[arra_val])
        }
      }
    }

    if(param_data!=null && param_data.proxyFiles!=null  && param_data.proxyFiles!=undefined)
    {
      for (let arra_val  in param_data.proxyFiles)
      {

        let url=param_data.proxyFiles[arra_val].url;
        let result= form.proxyFiles.find(obj => obj.url ==url );

        if (!result) {
          form.proxyFiles.push(param_data.proxyFiles[arra_val])
        }
      }
    }

    if(param_data!=null &&  param_data.permitFiles!=null  &&  param_data.permitFiles!=undefined)
    {


      for (let arra_val  in param_data.permitFiles)
      {
        let url=param_data.permitFiles[arra_val].url;
        let result= form.permitFiles.find(obj => obj.url ==url );
        if (!result) {
          form.permitFiles.push(param_data.permitFiles[arra_val])
        }
      }

    }
    param_data.transactionId=form.id;
    param_data.transactionType=form.transactionType;
    param_data.gujia=form.gujia;
    param_data.unifiedSocialCreditCode=form.unifiedSocialCreditCode;
    param_data.contactAddress=form.contactAddress;
    param_data.landEvaluateReport=form.landEvaluateReport;
    param_data.landOutContract=form.landOutContract;
    param_data.assetsEvaluateReport=form.assetsEvaluateReport;
    param_data.transferContract=form.transferContract;
    param_data.permitFiles=form.permitFiles;
    param_data.proxyFiles=form.proxyFiles;
    if (form.fileId) {
      CommonService.updateAddFiles( {
        transactionId:form.fileId,
        transactionType: form.transactionType,
        unifiedSocialCreditCode: form.unifiedSocialCreditCode,
        contactAddress: form.contactAddress,
        gujia: form.gujia,
        landOutContract: form.landOutContract,
        transferContract: form.transferContract,
        landEvaluateReport:form.landEvaluateReport ,
        assetsEvaluateReport: form.assetsEvaluateReport,
        permitFiles: form.permitFiles,
        proxyFiles: form.proxyFiles
      }).then(res => {
        if (res && res.code === 0) {
          Message.success('修改资料成功');
        }else {
          console.log(res)
          Message.error(res.msg);
        }
      });
    } else {
      CommonService.addAgreementTradeFiles(param_data,form.transactionType ,form).then(res => {
        if (res && res.code === 0) {
          Message.success('添加资料成功');
        }else {
          console.log(res)
          Message.error(res.msg);
        }
      });
    }
    visible.value = false;
  } catch (err) {

    Message.error(err.msg);
  } finally {
    loading.value = false;
  }
};


const getAgreementTradeFileData = (id) => {

  loading.value = true;
  CommonService.getAddFiles(id,form.transactionType ).then((res) => {
    console.log(res)
    if (res.data) {
      Object.assign(form, res.data);
      param_data.landOutContract=form.landOutContract;
      param_data.transferContract=form.transferContract;
      param_data.unifiedSocialCreditCode=form.unifiedSocialCreditCode;
      param_data.contactAddress=form.contactAddress;
      param_data.gujia=form.gujia;
      param_data.landEvaluateReport=form.landEvaluateReport;
      param_data.permitFiles=form.permitFiles;
      param_data.proxyFiles=form.proxyFiles;
      param_data.assetsEvaluateReport=form.assetsEvaluateReport;
    }
  }).finally(() => {
    loading.value = false;
  });
};



// 暴露变量
defineExpose({
  openDialog,
});
</script>
<style >
.form-item-desc {
  font-size: 12px;
  color: red;
}
.min-width{
  width: 1300px;
}
.upload-file{
  width: 150px;
}
.navWrap_center_ul {
  width: 150px;
}
</style>
